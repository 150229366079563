import { groq } from 'next-sanity';
import { LinkType } from '../helpers';
import { SiteIdType } from '../../sites';
import { BlockContainerWithBackgroundProperties } from '@klokgroep/shared-components/src/BlockContainerWithBackground';
import { createParentsQuery } from '../helpers/createHrefQueryWithParents';

const blogItemFields = groq`{
"datePublished": select(
  defined( datePublished ) => datePublished,
    _updatedAt
  ),
  _createdAt,
  _type,
  _id,
  _key,
  showOnHolding,
  "hero": blogHero,
  siteId,
  categories[]->{slug,name, _type},
  "imageAsset": coalesce(
    pageHero.previewImage,
    blogHero.images[0],
    pageHero.mediaItems[0],
    pageHero.image,
    jobHero.images[0],
    jobHero.overviewImage,
    corporateProjectHero.images[0],
    corporateProjectHero.mediaItems[0],
  ),
  "title": blogHero.title,
  previewText,
  specialism->{slug,name},
  "link": {
    "type": "reference",
    "href": {
      slug,
     _type,
     ${createParentsQuery()}
    },
  }
}`;

export const relatedContentItemQueryString = groq`{
  "datePublished": select(
    defined( datePublished ) => datePublished,
    _updatedAt
   ),
  _createdAt,
  _type,
  _id,
  _key,
  siteId,
  "imageAsset": coalesce(
    pageHero.previewImage,
    pageHero.mediaItems[0],
    pageHero.image,
    jobHero.images[0],
    jobHero.overviewImage,
    blogHero.images[0],
    corporateProjectHero.images[0],
    corporateProjectHero.mediaItems[0],
  ),
  "title": coalesce(
    blogHero.title,
    pageHero.title,
    jobHero.title,
    title,
  ),
  "previewText": coalesce(
    previewText,
    blogHero.intro,
    pageHero.text,
    jobHero.intro,
  ),
  "corporateProjectFields": select(
    _type == "corporate-project" => [
      location,
      properties.locationType[0]->label,
      properties.status[0]->label,
      properties.activities[0]->label,
      properties.workAreas[0]->label,
      properties.concepts[0]->label,
      properties.features[0]->label,
    ],
    [],
  ),
  categories[]->{slug,name, _type},
  "link": {
    "type": "reference",
    siteId,
    "href": {
      slug,
     _type,
     ${createParentsQuery()}
    },
  }
}`;

//shared filter logic
const getSiteIdFilter = (siteId: SiteIdType | undefined) =>
  siteId === 'holding' || !siteId ? groq`(siteId == "holding" || !defined(siteId))` : groq`siteId == '${siteId}'`;

const ORDERING = groq`order(_updatedAt desc) | order(_createdAt desc)`;
const NOT_SELF_FILTER_FOR_MODULES = groq`!(_id match array::join(["*", ^._id], ""))`;
const NOT_SELF_FILTER_FOR_ROOT = groq`!(_id match array::join(["*", ^.^.^._id], ""))`;
const EXCLUDE_NO_INDEX_QUERY = groq`seo.noIndex != true && parent->seo.noIndex != true`;

//related content is a content module on pages and a fixed item in blogs and projects. That is why we need two approaches to fetch the correct data
export const getRelatedContentItemsQueryStringForContentModules = (siteId: SiteIdType) => {
  //If less than three items are selected, we need to fetch additional items from the same category. We need 3 but here we get 4. We filter out the last item in the component. just in case one of the items is the current document itself
  const selectAdditionalRelatedItems = (index: number) =>
    groq`
  select(
    //if the first item is a filter, we complement it with items of the same category
    defined([...^.contentModules[_type == 'relatedContent'].items[0].filter._ref][0]) => *[references([...^.^.contentModules[_type == 'relatedContent'].items[0].filter._ref]) 
    && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
    
    defined([...^.corporateContentModules[_type == 'relatedContent'].items[0].filter._ref][0]) => *[references([...^.^.corporateContentModules[_type == 'relatedContent'].items[0].filter._ref]) && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],

    //otherwise we complement it with items of the same document type as type 1. We need to do this for both contentModules and corporateContentModules
    'corporate-project' in ^.corporateContentModules[_type == 'relatedContent'].items[0].type => *[_type == 'corporate-project' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
    'corporate-project' in ^.contentModules[_type == 'relatedContent'].items[0].type => *[_type == 'corporate-project' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
    'blogPost' in ^.corporateContentModules[_type == 'relatedContent'].items[0].type => *[_type == 'blogPost' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${blogItemFields} [${index}],
    'blogPost' in ^.contentModules[_type == 'relatedContent'].items[0].type => *[_type == 'blogPost' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${blogItemFields} [${index}],

    //if there is no  item defined, we fetch items of the same type as the current document
    ^._type == 'blogPost' => *[_type == 'blogPost' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${blogItemFields} [${index}],
    ^._type == 'corporate-project' => *[_type == 'corporate-project' && ${getSiteIdFilter(
      siteId
    )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
     *[_type match '*-page' && ${getSiteIdFilter(
       siteId
     )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
   )`;

  return groq`
  "documentId": ^._id,
  "items": [
    ...items[] {
      ...select(
        defined(page) => {
          ...page->${relatedContentItemQueryString}
            },
          defined(filter) && type == 'corporate-project' => *[(references(^.filter._ref) && _type == 'corporate-project' && ${getSiteIdFilter(
            siteId
          )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${relatedContentItemQueryString} [0], 
          defined(filter) && type == 'blogPost' => *[(references(^.filter._ref) && _type == 'blogPost' && ${getSiteIdFilter(
            siteId
          )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${blogItemFields} [0],
          *[(_type match "*-page" && ${getSiteIdFilter(
            siteId
          )} && ${NOT_SELF_FILTER_FOR_MODULES} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${relatedContentItemQueryString} [0...2]
        ),
      },
      ${selectAdditionalRelatedItems(1)},
      ${selectAdditionalRelatedItems(2)},
      ${selectAdditionalRelatedItems(3)},
      ${selectAdditionalRelatedItems(4)},
  ][0...4]`;
};

//this is similar to getRelatedContentItemsQueryStringForContentModules but some slight differences
export const getRelatedContentItemsQueryStringForRoot = (siteId: SiteIdType | undefined, preview?: boolean) => {
  if (preview === false) {
    const selectAdditionalRelatedItems = (index: number) => groq`
    select(
      defined(^.relatedContent.items[0].filter._ref) && ^.relatedContent.items[0].type == 'corporate-project' => *[references(^.^.relatedContent.items[0].filter._ref) 
      && ${getSiteIdFilter(siteId)}
      && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
  
      defined(^.relatedContent.items[0].filter._ref) => *[references(^.^.relatedContent.items[0].filter._ref) 
      && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
      ^.relatedContent.items[0].type == 'corporate-project' => *[_type == 'corporate-project' && ${getSiteIdFilter(
        siteId
      )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
  
      ^.relatedContent.items[0].type == 'blogPost' => *[_type == 'blogPost' && ${getSiteIdFilter(
        siteId
      )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${blogItemFields} [${index}],
  
      ^._type == 'blogPost' => *[_type == 'blogPost' && ${getSiteIdFilter(
        siteId
      )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${blogItemFields} [${index}],
  
      ^._type == 'corporate-project' => *[_type == 'corporate-project' && ${getSiteIdFilter(
        siteId
      )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
  
       *[_type match '*-page' && ${getSiteIdFilter(
         siteId
       )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY} ] | ${ORDERING} ${relatedContentItemQueryString} [${index}],
     )`;

    return groq`
    "documentId": ^._id,
    "items": [
      ...items[] {
        ...select(
            defined(page) => {
              ...page->${relatedContentItemQueryString}
              },
            defined(filter) && type == 'corporate-project' => *[(references(^.filter._ref) && _type == 'corporate-project' && ${getSiteIdFilter(
              siteId
            )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${relatedContentItemQueryString} [0], 
            defined(filter) && type == 'blogPost' => *[(references(^.filter._ref) && _type == 'blogPost' && ${getSiteIdFilter(
              siteId
            )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${blogItemFields} [0],
            *[(_type match "*-page" && ${getSiteIdFilter(
              siteId
            )} && ${NOT_SELF_FILTER_FOR_ROOT} && ${EXCLUDE_NO_INDEX_QUERY})] | ${ORDERING} ${relatedContentItemQueryString} [0...2]
          ),
        },
        ${selectAdditionalRelatedItems(1)},
        ${selectAdditionalRelatedItems(2)},
        ${selectAdditionalRelatedItems(3)},
        ${selectAdditionalRelatedItems(4)},
    ][0...4]`;
  }
  return groq`
  "documentId": null,
  "items": []
  `;
};

// BD 2024-09-29: If it's in preview, we don't load the related content. Preview cannot handle some functions used in the queries.
export const getRelatedContentQuery = (siteId: SiteIdType, preview?: boolean) =>
  preview === false
    ? groq`_type == 'relatedContent' => {
    _type,
    _id,
    _key,
   title,
   backgroundColor,
   ${getRelatedContentItemsQueryStringForContentModules(siteId)},
  },`
    : undefined;

export interface RelatedContentBlockProperties {
  _id: string;
  _type: 'relatedContent';
  _key: string;
  title?: string;
  documentId: string;
  backgroundColor?: BlockContainerWithBackgroundProperties['backgroundColor'];
  items?: {
    datePublished?: string;
    _key?: string;
    _id?: string;
    _createdAt?: string;
    _type?: string;
    previewText?: string;
    corporateProjectFields?: string[];
    imageAsset?: NonNullable<Sanity.Schema.ImageAndLinks['image']> & {
      caption?: string;
    };
    title?: string;
    siteId?: SiteIdType;
    categories?: Sanity.Schema.CategoryTaxonomy[];
    link?: LinkType;
  }[];
}
